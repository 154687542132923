import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Popover from "react-bootstrap/Popover";

import question_model from "../../../../assets/models/question.glb";
import { createNetworkedEntity } from "../../../../utils/create-networked-entity";
import { Tooltip } from "react-tooltip";
import { useAuth0 } from "@auth0/auth0-react";
import { toast } from "react-hot-toast";

export default function CreateFreeTextQuestionMenu(props) {
    const [title, setTitle] = useState([{ question: "" }]);
    const [inputFields, setInputFields] = useState([{ question: "" }]);
    const { getAccessTokenSilently } = useAuth0();

    const addQuestion = () => {
        let isEmpty = false;

        inputFields.forEach(input => {
            console.log(input);
            if (!input.question) {
                toast.error("You need to write something");
                isEmpty = true;
            }
        });

        if (isEmpty) return;

        const post_payload = {
            current_classroom: window.APP.classroom.id,
            freetextquestion_set: inputFields
        };
        
        fetch(process.env.REACT_APP_MM_API_URL + "assessments/freetext/", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(post_payload)
        })
            .then(resp => {
                console.log(resp);
                return resp.json();
            })
            .then(data => {
                console.log("Successfully created question on server, now spawn it in the room!");

                const eid = createNetworkedEntity(APP.world, "question", {
                    object_id: null,
                    variant: "freetext",
                    bundle: { id: data.id, version: 0, freetextquestion_set: data.freetextquestion_set }
                });
                // Add question component to eid, or inflate?

                const avatarPov = document.querySelector("#avatar-pov-node").object3D;
                const obj = APP.world.eid2obj.get(eid);
                obj.position.copy(avatarPov.localToWorld(new THREE.Vector3(0, 0, -1.5)));
                obj.lookAt(avatarPov.getWorldPosition(new THREE.Vector3()));

                setTimeout(() => {
                    window.APP.objectHelper.save(eid, null, "question", null, "question", null, data.id);
                }, 1000);
                props.onFinish();
                return eid;
            });
    };

    const handleFormChange = (index, event) => {
        let data = [...inputFields];
        data[index]["question"] = event.target.value;
        setInputFields(data);
    };

    const addFields = () => {
        let newfield = { question: "" };
        setInputFields([...inputFields, newfield]);
    };

    const removeFields = index => {
        let data = [...inputFields];
        data.splice(index, 1);
        setInputFields(data);
    };

    return (
        <div className="p-3">
            <Tooltip id="addMediaTooltip" />
            <Button
                data-tooltip-id="addMediaTooltip"
                data-tooltip-content="Back to list"
                variant="light"
                onClick={() => props.onBack()}
            >
                <i className="bi bi-arrow-left"></i>
            </Button>
            <Button
                data-tooltip-id="addMediaTooltip"
                data-tooltip-content="Guide to Components"
                href="https://megaminds.tawk.help/article/components"
                target="_blank"
                variant="light"
            >
                <i className="bi bi-question-circle-fill"></i>
            </Button>
            <hr />
            <p className="fw-bold">Questions</p>
            <>
                <Row className="pb-1">
                    <Col>
                        {inputFields.map((input, index) => {
                            return (
                                <Row className="pb-1" key={index}>
                                    <Col>
                                        <textarea
                                            value={input.question}
                                            required
                                            maxlength="450"
                                            className="form-control"
                                            placeholder="What is your favorite color?"
                                            type="text"
                                            onChange={event => handleFormChange(index, event)}
                                        ></textarea>
                                    </Col>
                                    <Col xs={2} className="d-flex align-items-center">
                                        <button
                                            className="btn btn-danger w-100 d-flex justify-content-center align-items-center"
                                            onClick={() => removeFields(index)}
                                        >
                                            <i className="bi bi-trash-fill"></i>
                                        </button>
                                    </Col>
                                </Row>
                            );
                        })}
                    </Col>
                </Row>
                <Row className="pb-1 pt-1">
                    <Col xs={12}>
                        <button
                            className="btn btn-primary w-100 d-flex justify-content-center align-content-center"
                            onClick={addFields}
                        >
                            + Add question
                        </button>
                    </Col>
                </Row>
                {/*
                <div className="mb-3 form-check">
                    <input
                        id="hideCheckbox"
                        className="form-check-input"
                        type="checkbox"
                        onChange={(e) =>
                            setHideQuestionObject(e.target.checked)
                        }
                    />
                    <label className="form-check-label" htmlFor="hideCheckbox">
                        Hide Questionmark in sessions (for scavenger hunts,
                        secret questions, etc)
                    </label>
                </div>
                */}
                <Row className="d-grid g-1 pt-3 border-top">
                    <Button
                        variant="success"
                        type="button"
                        disabled={inputFields.length <= 0}
                        onClick={() => addQuestion()}
                    >
                        Add
                    </Button>
                </Row>
            </>
        </div>
    );
}
