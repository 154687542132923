import { Question, Networked } from "../../bit-components";

export function createMCQObject(bundle, object_id) {
    const questions = bundle.mcqquestion_set;

    // Create container / card
    let submittedQuestions = JSON.parse(window.localStorage.getItem("mcq questions"));
    console.log("SubmittedQuestions", !!submittedQuestions);

    const container = document.createElement("div");
    container.id = object_id;
    container.style.opacity = 100;

    var submitted = submittedQuestions?.some(
        (item) => item.question === bundle.id && item.version === bundle.version
      );
    var answers = [];

    questions.forEach((question, index) => {
        const rootContainer = document.createElement("div");

        const questionContainer = document.createElement("div");
        questionContainer.className = "css2d-question-container card card-body";

        const label = document.createElement("label");
        label.className = "css2d-label fw-bold mb-2";
        label.textContent = question.question;
        questionContainer.appendChild(label);

        question.mcqanswer_set.forEach((answer, ansIndex) => {
            console.log("Answer", answer);
            const answerContainer = document.createElement("div");
            answerContainer.className = "css2d-answer-container form-check m-2";

            let input = document.createElement("input");
            input.className = "form-check-input";
            input.id = "label-" + index.toString();
            input.name = index.toString();
            input.type = "radio";
            input.id = "label-" + index.toString();
            input.disabled = submitted;

            const answerLabel = document.createElement("label");
            answerLabel.className = "css2d-answer-label form-check-label";
            answerLabel.textContent = answer.answer;

            answerContainer.appendChild(answerLabel);
            answerContainer.appendChild(input);

            questionContainer.appendChild(answerContainer);

            answers.push({
                parentEl: answerContainer,
                el: input,
                question: question.id,
                answer: answer.id,
                correct: answer.correct
            });
        });
        if (!!submitted) {
            questionContainer.className = "css2d-question-container card card-body";
            questionContainer.style.backgroundColor = "lightgrey";
        }

        rootContainer.appendChild(questionContainer);

        container.appendChild(rootContainer);
    });

    const button = document.createElement("button");

    button.className = "css2d-button btn btn-purple-dark mt-2";
    button.textContent = "Submit";

    button.addEventListener("click", () => {
        console.log("Clicked");
        // POST to

        let submittedQuestions = JSON.parse(window.localStorage.getItem("mcq questions"));
        console.log("SubmittedQuestions", !!submittedQuestions);

        if (!!submittedQuestions) {
            submittedQuestions.push({question: bundle.id, version: bundle.version});
            window.localStorage.setItem("mcq questions", JSON.stringify(submittedQuestions));
            console.log("Already had submitted stuff", submittedQuestions);
        } else {
            const newQuestions = [{question: bundle.id, version: bundle.version}];
            window.localStorage.setItem("mcq questions", JSON.stringify(newQuestions));
        }

        let questionArray = [];

        answers.forEach((answer, index) => {
            if (answer.correct) {
                console.log("Correct answer!");
                answer.parentEl.className = "css2d-answer-container form-check text-white bg-success m-2";
                answer.el.disabled = true;
            } else {
                answer.parentEl.className = "css2d-answer-container form-check incorrect-question-bg m-2";
                answer.el.disabled = true;
            }

            if (answer.el.checked) {
                questionArray.push({
                    question: answer.question,
                    answer: answer.answer,
                    student_name: window.APP.store.state.profile.displayName
                });
            }
        });

        let payload = questionArray;

        console.log(payload);

        let endpoint;

        if (window.location.hostname === "localhost") {
            endpoint = "http://localhost:8000/api/assessments/mcq/answer/";
        } else if (window.location.hostname === "megaminds-dev.world") {
            endpoint = "https://api.megaminds-dev.world/api/assessments/mcq/answer/";
        } else {
            endpoint = "https://api.megaminds.world/api/assessments/mcq/answer/";
        }

        fetch(endpoint, {
            method: "post",
            headers: {
                Accept: "application/json, text/plain, */*",
                "Content-Type": "application/json"
            },
            body: JSON.stringify(payload)
        });

        submitted = true;
        container.style.pointerEvents = "none";
        button.className = "css2d-button btn btn-success btn-disabled disabled mt-2";
        button.textContent = "Submitted";
        button.disabled = "true";
        button.disabled = "true";
    });

    if (!!submittedQuestions) {
        console.log("Submitted Questions before!");
        if (submittedQuestions.some(
            (item) => item.question === bundle.id && item.version === bundle.version
          )) {
            console.log("Answers include", answers);

            submitted = true;
            container.className = "css2d-question-container";
            container.style.opacity = 40;
            container.style.pointerEvents = "none";

            button.className = "css2d-button btn btn-success btn-disabled disabled mt-2";
            button.textContent = "Already Submitted";
            button.disabled = "true";
            button.disabled = "true";
        }
    }

    container.appendChild(button);

    const outerContainer = document.createElement("div");
    outerContainer.className = "css2d-container";
    outerContainer.style.opacity = 100;

    outerContainer.appendChild(container);

    return outerContainer;
}

export function createInnerMCQObject(bundle, object_id) {
    const questions = bundle.mcqquestion_set;

    // Create container / card
    let submittedQuestions = JSON.parse(window.localStorage.getItem("mcq questions"));
    console.log("SubmittedQuestions", !!submittedQuestions);

    const container = document.createElement("div");
    container.id = object_id;
    container.style.opacity = 100;

    var submitted = submittedQuestions?.some(
        (item) => item.question === bundle.id && item.version === bundle.version
      );
    var answers = [];

    questions.forEach((question, index) => {
        const rootContainer = document.createElement("div");

        const questionContainer = document.createElement("div");
        questionContainer.className = "css2d-question-container card card-body";

        const label = document.createElement("label");
        label.className = "css2d-label fw-bold mb-2";
        label.textContent = question.question;
        questionContainer.appendChild(label);

        question.mcqanswer_set.forEach((answer, ansIndex) => {
            console.log("Answer", answer);
            const answerContainer = document.createElement("div");
            answerContainer.className = "css2d-answer-container form-check m-2";

            let input = document.createElement("input");
            input.className = "form-check-input";
            input.id = "label-" + index.toString();
            input.name = index.toString();
            input.type = "radio";
            input.id = "label-" + index.toString();
            input.disabled = submitted;

            const answerLabel = document.createElement("label");
            answerLabel.className = "css2d-answer-label form-check-label";
            answerLabel.textContent = answer.answer;

            answerContainer.appendChild(answerLabel);
            answerContainer.appendChild(input);

            questionContainer.appendChild(answerContainer);

            answers.push({
                parentEl: answerContainer,
                el: input,
                question: question.id,
                answer: answer.id,
                correct: answer.correct
            });
        });
        if (!!submitted) {
            questionContainer.className = "css2d-question-container card card-body";
            questionContainer.style.backgroundColor = "lightgrey";
        }

        rootContainer.appendChild(questionContainer);

        container.appendChild(rootContainer);
    });

    const button = document.createElement("button");

    button.className = "css2d-button btn btn-purple-dark mt-2";
    button.textContent = "Submit";

    button.addEventListener("click", () => {
        console.log("Clicked");
        // POST to

        let submittedQuestions = JSON.parse(window.localStorage.getItem("mcq questions"));
        console.log("SubmittedQuestions", !!submittedQuestions);

        if (!!submittedQuestions) {
            submittedQuestions.push({question: bundle.id, version: bundle.version});
            window.localStorage.setItem("mcq questions", JSON.stringify(submittedQuestions));
            console.log("Already had submitted stuff", submittedQuestions);
        } else {
            const newQuestions = [{question: bundle.id, version: bundle.version}];
            window.localStorage.setItem("mcq questions", JSON.stringify(newQuestions));
        }

        let questionArray = [];

        answers.forEach((answer, index) => {
            if (answer.correct) {
                console.log("Correct answer!");
                answer.parentEl.className = "css2d-answer-container form-check text-white bg-success m-2";
                answer.el.disabled = true;
            } else {
                answer.parentEl.className = "css2d-answer-container form-check incorrect-question-bg m-2";
                answer.el.disabled = true;
            }

            if (answer.el.checked) {
                questionArray.push({
                    question: answer.question,
                    answer: answer.answer,
                    student_name: window.APP.store.state.profile.displayName
                });
            }
        });

        let payload = questionArray;

        console.log(payload);

        let endpoint;

        if (window.location.hostname === "localhost") {
            endpoint = "http://localhost:8000/api/assessments/mcq/answer/";
        } else if (window.location.hostname === "megaminds-dev.world") {
            endpoint = "https://api.megaminds-dev.world/api/assessments/mcq/answer/";
        } else {
            endpoint = "https://api.megaminds.world/api/assessments/mcq/answer/";
        }

        fetch(endpoint, {
            method: "post",
            headers: {
                Accept: "application/json, text/plain, */*",
                "Content-Type": "application/json"
            },
            body: JSON.stringify(payload)
        });

        submitted = true;
        container.style.pointerEvents = "none";
        button.className = "css2d-button btn btn-success btn-disabled disabled mt-2";
        button.textContent = "Submitted";
        button.disabled = "true";
        button.disabled = "true";
    });

    if (!!submittedQuestions) {
        console.log("Submitted Questions before!");
        if (submittedQuestions.some(
            (item) => item.question === bundle.id && item.version === bundle.version
          )) {
            console.log("Answers include", answers);

            submitted = true;
            container.className = "css2d-question-container";
            container.style.opacity = 40;
            container.style.pointerEvents = "none";

            button.className = "css2d-button btn btn-success btn-disabled disabled mt-2";
            button.textContent = "Already Submitted";
            button.disabled = "true";
            button.disabled = "true";
        }
    }

    container.appendChild(button);

    return container;
}
