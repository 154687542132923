export function createQuestionObject(bundle, object_id) {
    // Create container / card

    console.log("Got object id", object_id);
    console.log("Got bundle ", bundle);
    let submittedQuestions = JSON.parse(window.localStorage.getItem("freetext questions"));
    const questions = bundle.freetextquestion_set;

    const container = document.createElement("div");

    var submitted = bundle.id !== null && submittedQuestions?.some(
        (item) => item.question === bundle.id && item.version === bundle.version
      );
    var answers = [];

    questions.forEach((question, index) => {
        const questionContainer = document.createElement("div");
        questionContainer.className = "css2d-question-container mb-3";

        const label = document.createElement("label");
        label.className = "css2d-label";
        label.textContent = question.question;
        questionContainer.appendChild(label);

        const input = document.createElement("input");
        input.id = question.question;
        input.type = question.type;
        input.className = "css2d-input form-control";
        input.disabled = submitted;

        answers.push({ el: input, question: question.id });

        questionContainer.appendChild(input);

        container.appendChild(questionContainer);
    });

    const button = document.createElement("button");    

    button.className = "css2d-button btn btn-purple-dark mt-2";
    button.textContent = "Submit";

    button.addEventListener("click", () => {
        console.log("Clicked outer");
        // POST to
        let submittedQuestions = JSON.parse(window.localStorage.getItem("freetext questions"));
        console.log("SubmittedQuestions outer", !!submittedQuestions);

        if (!!submittedQuestions) {
            submittedQuestions.push({question: bundle.id, version: bundle.version});
            window.localStorage.setItem("freetext questions", JSON.stringify(submittedQuestions));
            console.log("Already had submitted stuff", submittedQuestions);
        } else {
            const newQuestions = [{question: bundle.id, version: bundle.version}];
            window.localStorage.setItem("freetext questions", JSON.stringify(newQuestions));
        }

        let questionArray = [];

        answers.forEach((answer, index) => {
            answer.el.disabled = true;
            questionArray.push({
                question: answer.question,
                answer: answer.el.value,
                student_name: window.APP.store.state.profile.displayName
            });
        });

        let payload = questionArray;

        console.log("Payload", payload);

        let endpoint;
        if (window.location.hostname === "localhost") {
            endpoint = "http://localhost:8000/api/assessments/freetext/answer/";
        } else if (window.location.hostname === "megaminds-dev.world") {
            endpoint = "https://api.megaminds-dev.world/api/assessments/freetext/answer/";
        } else {
            endpoint = "https://api.megaminds.world/api/assessments/freetext/answer/";
        }

        fetch(endpoint, {
            method: "post",
            headers: {
                Accept: "application/json, text/plain, */*",
                "Content-Type": "application/json"
            },
            body: JSON.stringify(payload)
        });

        submitted = true;
        button.className = "css2d-button btn btn-success btn-disabled disabled mt-2";
        button.textContent = "Submitted";
        button.disabled = "true";
        button.disabled = "true";
    });

    if (bundle.id && !!submittedQuestions) {
        console.log("Submitted Questions before!");
        if (submittedQuestions.some(
            (item) => item.question === bundle.id && item.version === bundle.version
          )) {
            console.log("Answers include", answers);

            submitted = true;
            container.className = "css2d-question-container";
            container.style.opacity = 40;
            container.style.pointerEvents = "none";

            button.className = "css2d-button btn btn-success btn-disabled disabled mt-2";
            button.textContent = "Already Submitted";
            button.disabled = "true";
            button.disabled = "true";
        }
    }

    container.appendChild(button);

    const outerContainer = document.createElement("div");
    outerContainer.className = "css2d-container";
    outerContainer.style.opacity = 100;
    outerContainer.style.pointerEvents = "none";

    outerContainer.appendChild(container);

    return outerContainer;
}

export function createInnerQuestionObject(bundle, object_id) {
    // Create container / card

    console.log("Inner object id", object_id);
    console.log("Inner bundle ", bundle);
    let submittedQuestions = JSON.parse(window.localStorage.getItem("freetext questions"));
    const questions = bundle.freetextquestion_set;

    const container = document.createElement("div");

    var submitted = bundle.id !== null && submittedQuestions?.some(
        (item) => item.question === bundle.id && item.version === bundle.version
      );
    var answers = [];

    questions.forEach((question, index) => {
        const questionContainer = document.createElement("div");
        questionContainer.className = "css2d-question-container mb-3";

        const label = document.createElement("label");
        label.className = "css2d-label";
        label.textContent = question.question;
        questionContainer.appendChild(label);

        const input = document.createElement("input");
        input.id = question.question;
        input.type = question.type;
        input.className = "css2d-input form-control";
        input.disabled = submitted;

        answers.push({ el: input, question: question.id });

        questionContainer.appendChild(input);

        container.appendChild(questionContainer);
    });

    const button = document.createElement("button");    

    button.className = "css2d-button btn btn-purple-dark mt-2";
    button.textContent = "Submit";

    button.addEventListener("click", () => {
        console.log("Clicked outer");
        // POST to
        let submittedQuestions = JSON.parse(window.localStorage.getItem("freetext questions"));
        console.log("SubmittedQuestions outer", !!submittedQuestions);

        if (!!submittedQuestions) {
            submittedQuestions.push({question: bundle.id, version: bundle.version});
            window.localStorage.setItem("freetext questions", JSON.stringify(submittedQuestions));
            console.log("Already had submitted stuff", submittedQuestions);
        } else {
            const newQuestions = [{question: bundle.id, version: bundle.version}];
            window.localStorage.setItem("freetext questions", JSON.stringify(newQuestions));
        }

        let questionArray = [];

        answers.forEach((answer, index) => {
            answer.el.disabled = true;
            questionArray.push({
                question: answer.question,
                answer: answer.el.value,
                student_name: window.APP.store.state.profile.displayName
            });
        });

        let payload = questionArray;

        console.log("Payload", payload);

        let endpoint;
        if (window.location.hostname === "localhost") {
            endpoint = "http://localhost:8000/api/assessments/freetext/answer/";
        } else if (window.location.hostname === "megaminds-dev.world") {
            endpoint = "https://api.megaminds-dev.world/api/assessments/freetext/answer/";
        } else {
            endpoint = "https://api.megaminds.world/api/assessments/freetext/answer/";
        }

        fetch(endpoint, {
            method: "post",
            headers: {
                Accept: "application/json, text/plain, */*",
                "Content-Type": "application/json"
            },
            body: JSON.stringify(payload)
        });

        submitted = true;
        button.className = "css2d-button btn btn-success btn-disabled disabled mt-2";
        button.textContent = "Submitted";
        button.disabled = "true";
        button.disabled = "true";
    });

    if (bundle.id && !!submittedQuestions) {
        console.log("Submitted Questions before!");
        if (submittedQuestions.some(
            (item) => item.question === bundle.id && item.version === bundle.version
          )) {
            console.log("Answers include", answers);

            submitted = true;
            container.className = "css2d-question-container";
            container.style.opacity = 40;
            container.style.pointerEvents = "none";

            button.className = "css2d-button btn btn-success btn-disabled disabled mt-2";
            button.textContent = "Already Submitted";
            button.disabled = "true";
            button.disabled = "true";
        }
    }

    container.appendChild(button);

    return container;
}
