import React, { useEffect, useState } from "react";
import ExtendedModal from "./modals/ExtendedModal";

import UserDetailMenu from "./right/UserDetailMenu";
import PreferencesMenu from "./floating/PreferencesMenu";
import ObjectDetailMenu from "./right/ObjectDetailMenu";
import EditObjectMenu from "./right/EditObjectMenu";
import RoomSettingsMenu from "./right/RoomSettingsMenu";

export default function RightMenu(props) {
    const [object, setObject] = useState(null);

    useEffect(() => {
        props.scene.addEventListener("right_menu_changed", el => {
            if (el.detail === null) {
                setObject(null);
                props.scene.systems["hubs-systems"]["cameraSystem"].transformControls.detach();
                if (props.scene.systems["hubs-systems"]["cameraSystem"].helper) {
                    props.scene.systems["hubs-systems"]["cameraSystem"].helper.visible = false;
                }
            } else {
                if (el.detail === object) {
                    setObject(null);
                } else {
                    setObject(el.detail);
                }
            }
        });
    }, []);

    if (!object) {
        return null;
    }

    if (object === "room_settings") {
        return (
            <ExtendedModal
                position="middle-end"
                title={"Room Settings"}
                closePopover={() => {
                    props.scene.emit("right_menu_changed", null);
                }}
                content={<RoomSettingsMenu />}
            />
        );
    }

    if (object === "preferences") {
        return (
            <ExtendedModal
                position="middle-end"
                title={"Preferences"}
                closePopover={() => {
                    setObject(null);
                }}
                content={<PreferencesMenu audioStatus={props.audioStatus} onClose={() => setObject(null)} />}
            />
        );
    }

    if (object.variant && object.variant === "user_settings") {
        return <UserDetailMenu setObject={setObject} user={object.payload.user} img={object.payload.img} />;
    }

    let obj = window.APP.world.eid2obj.get(object);

    if (obj?.parent?.name === "Interactable Media") {
        obj = obj.parent;
    } 

    if (!window.APP.editMode || !window.APP.objectHelper.can("can_create")) {
        // TODO: Toast instead of showing menu on Assessment Questionmark, Embed Placeholders, etc.

        return (
            <ExtendedModal
                position="middle-end"
                title={"Object Details"}
                closePopover={() => {
                    props.scene.systems["hubs-systems"].cameraSystem.uninspect();
                    props.scene.systems["hubs-systems"].cameraSystem.isInsideMenu = null;
                    props.scene.systems["hubs-systems"]["cameraSystem"].transformControls.detach();
                    if (props.scene.systems["hubs-systems"]["cameraSystem"].helper) {
                        props.scene.systems["hubs-systems"]["cameraSystem"].helper.visible = false;
                    }
                    props.scene.emit("right_menu_changed", null);
                }}
                content={
                    <ObjectDetailMenu
                        scene={props.scene}
                        closePopover={() => {
                            props.scene.systems["hubs-systems"].cameraSystem.uninspect();
                            props.scene.systems["hubs-systems"].cameraSystem.isInsideMenu = null;
                            props.scene.systems["hubs-systems"]["cameraSystem"].transformControls.detach();
                            if (props.scene.systems["hubs-systems"]["cameraSystem"].helper) {
                                props.scene.systems["hubs-systems"]["cameraSystem"].helper.visible = false;
                            }
                            props.scene.emit("right_menu_changed", null);
                        }}
                        object={obj}
                        setObject={setObject}
                        eid={object}
                    />
                }
            />
        );
    }

    return (
        <ExtendedModal
            position="middle-end"
            title={"Edit Object"}
            closePopover={() => {
                props.scene.systems["hubs-systems"].cameraSystem.uninspect();
                props.scene.systems["hubs-systems"].cameraSystem.isInsideMenu = null;
                props.scene.systems["hubs-systems"]["cameraSystem"].transformControls.detach();
                if (props.scene.systems["hubs-systems"]["cameraSystem"].helper) {
                    props.scene.systems["hubs-systems"]["cameraSystem"].helper.visible = false;
                }
                props.scene.emit("right_menu_changed", null);
            }}
            content={
                <EditObjectMenu
                    scene={props.scene}
                    closePopover={() => {
                        props.scene.systems["hubs-systems"].cameraSystem.uninspect();
                        props.scene.systems["hubs-systems"].cameraSystem.isInsideMenu = null;
                        props.scene.systems["hubs-systems"]["cameraSystem"].transformControls.detach();
                        if (props.scene.systems["hubs-systems"]["cameraSystem"].helper) {
                            props.scene.systems["hubs-systems"]["cameraSystem"].helper.visible = false;
                        }
                        props.scene.emit("right_menu_changed", null);
                    }}
                    object={obj}
                    setObject={setObject}
                />
            }
        />
    );
}
