import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Popover from "react-bootstrap/Popover";

import question_model from "../../../../assets/models/question.glb";
import { createNetworkedEntity } from "../../../../utils/create-networked-entity";
import { Tooltip } from "react-tooltip";
import { useAuth0 } from "@auth0/auth0-react";
import { toast } from "react-hot-toast";
import { createInnerQuestionObject, createQuestionObject } from "../../../../utils/css2d/create-question-object";
import { Question } from "../../../../bit-components";
import { CSS2DObject } from "three/examples/jsm/renderers/CSS2DRenderer";

export default function EditFreeText(props) {
    let existingQuestions = [];

    props.bundle.freetextquestion_set.forEach(question => {
        let newfield = { question: question['question'] };
        existingQuestions.push(newfield);
    })
    
    const [inputFields, setInputFields] = useState(existingQuestions);    

    const { getAccessTokenSilently } = useAuth0();

    const saveQuestion = () => {
        let isEmpty = false;

        inputFields.forEach(input => {
            console.log(input);
            if (!input.question) {
                toast.error("You need to write something");
                isEmpty = true;
            }
        });

        if (isEmpty) return;

        const patch_payload = {
            current_classroom: window.APP.classroom.id,
            freetextquestion_set: inputFields,
        };

        fetch(process.env.REACT_APP_MM_API_URL + "assessments/freetext/" + props.bundle.id, {
            method: "PATCH",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(patch_payload)
        })
            .then(resp => {
                console.log(resp);
                return resp.json();
            })
            .then(data => {
                console.log("Successfully changed question on server!");
                toast.success("Changes were saved.");

                const question = APP.world.eid2obj.get(props.object.eid);
                const newContainer = createInnerQuestionObject(data, props.object.eid)
                
                question.element.innerHTML = ""
                question.element.appendChild(newContainer);

                Question.bundle[props.object.eid] = APP.getSid(data);
                window.APP.objectHelper.change(props.object.eid);

            });
    };

    const handleFormChange = (index, event) => {
        let data = [...inputFields];
        data[index]["question"] = event.target.value;
        setInputFields(data);
    };

    const addFields = () => {
        let newfield = { question: "" };
        setInputFields([...inputFields, newfield]);
    };

    const removeFields = index => {
        let data = [...inputFields];
        data.splice(index, 1);
        setInputFields(data);
    };

    return (
        <div>
            <div className="d-grid gap-2 d-md-block mb-3">
                <p className="fw-bold">Edit Questions</p>
            </div>
                <Row className="pb-1">
                    <Col>
                        {inputFields.map((input, index) => {
                            return (
                                <Row className="pb-1" key={index}>
                                    <Col>
                                        <textarea
                                            value={input.question}
                                            required
                                            maxlength="450"
                                            className="form-control"
                                            placeholder="What is your favorite color?"
                                            type="text"
                                            onChange={event => handleFormChange(index, event)}
                                        ></textarea>
                                    </Col>
                                    <Col xs={2} className="d-flex align-items-center">
                                        <button
                                            className="btn btn-danger w-100 d-flex justify-content-center align-items-center"
                                            onClick={() => removeFields(index)}
                                        >
                                            <i className="bi bi-trash-fill"></i>
                                        </button>
                                    </Col>
                                </Row>
                            );
                        })}
                    </Col>
                </Row>
                <Row className="pb-1 pt-1">
                    <Col xs={12}>
                        <button
                            className="btn btn-primary w-100 d-flex justify-content-center align-content-center"
                            onClick={addFields}
                        >
                            + Add question
                        </button>
                    </Col>
                </Row>
                <Row className="d-grid g-1 pt-3 mb-3 border-top">
                    <Button
                        variant="success"
                        type="button"
                        disabled={inputFields.length <= 0}
                        onClick={() => saveQuestion()}
                    >
                        Save
                    </Button>
                    <small className="mb-0 p-0 mt-1 fw-bold">Note: students need to refresh to see changes</small>
                </Row>
        </div>
    );
}
